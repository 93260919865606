<template>
  <div class="shopList">
    <van-icon class="fh" @click="$router.back(-1)" name="arrow-left" />
    <div class="top">
      <p class="jwd">lat:{{jwd.latitude}}-log:{{jwd.longitude}}</p>
      <p>预约</p>
      <van-search
          class="search"
          v-model="value"
          :show-action="true"
          placeholder="请输入搜索关键词"
      >
        <template #action>
          <div @click="onsearch">搜索</div>
        </template>
      </van-search>
      <div class="dw">
        <div class="dwbox" v-if="!CompanyId">
          <span @click="showAddress = true">省/市</span>
          <div class="dm">{{gsAddress[dzIdx[0]].AreaName}}-{{gsAddress[dzIdx[0]].children[dzIdx[1]].AreaName}}</div>
          <van-popup v-model="showAddress" position="bottom" :style="{ height: '40vh' }">
            <van-picker
                @confirm="onCancel"
                @cancel="showAddress=false"
                show-toolbar
                title="请选择地址"
                ref="getAddress"
                :value-key="'AreaName'"
                :columns="gsAddress"
                @change="upaddress"
            />
          </van-popup>
        </div>
        <div class="dwbox">
          <span @click="showCompany=true">单位</span>
          <div class="dm">{{CompanyList[CompanyIdx].CompanyName}}</div>
          <van-popup v-model="showCompany" position="bottom" :style="{ height: '40vh' }">
            <van-picker
                :columns-field-names="{text: 'CompanyName', value: 'CompanyId'}"
                @confirm="upCompany"
                @cancel="showCompany=false"
                show-toolbar
                title="请选择单位"
                ref="getCompany"
                :value-key="'CompanyName'"
                :columns="CompanyList"
            />
          </van-popup>
        </div>
      </div>
    </div>

    <div class="content">
      <div class="content-box" v-for="item in shopList" @click="$router.push({path: '/appointmentHome/reserveList',query: {shopId: item.ShopId}})">
        <div class="left">
          <img :src="'http://webadmin.visumall.com/pic/CompanyLogo/'+CompanyList[CompanyIdx].CompanyLogo" alt="">
          <p>进入预约</p>
        </div>
        <div class="right">
          <h3>{{item.ShopHealthCheckName?item.ShopHealthCheckName:item.ShopName}}</h3>
          <div>
            <img src="http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/WXMiniAPP/img/mddw.png" alt="">
            <span>{{item.ShopAddress}}</span>
          </div>
          <div>
            <img src="http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/WXMiniAPP/img/mdiphone.png" alt="">
            <span>{{item.ShopTel}}</span>
          </div>
        </div>
      </div>
    </div>

    <van-overlay :show="show">
      <div style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;">
        <van-loading size="24px" color="#ffffff" >加载中...</van-loading>
      </div>
    </van-overlay>
  </div>
</template>

<script>
export default {
  name: "shopList",
  data() {
    return {
      value: '',
      gsAddress:[
        {
          AreaName: '',
          children: [{
            AreaName: '',
            children: [{
              AreaName: ''
            }]
          }]
        }
      ],
      showAddress: false,
      dzIdx:  [0,0,0],
      dzItemId: [0,0,0],
      CompanyList: [{CompanyName: ''}],
      CompanyIdx: 0,
      shopList: [],
      showCompany: false,
      show: false,
      CompanyId: '',
      jwd: {
        latitude: 0,
        longitude: 0
      }
    }
  },
  created() {
    if (navigator.geolocation) {
      // 浏览器支持Geolocation API
      navigator.geolocation.getCurrentPosition(
          (position) =>  {
            // 获取位置成功，position 包含经纬度等信息
            // console.log("Latitude: " + );
            this.jwd = {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude
            }
            // console.log(this.jwd)
            if (this.$route.query.CompanyId) {
              this.CompanyId = this.$route.query.CompanyId
              this.getCmid(this.CompanyId)
              return
            }
            this.getAddress()
          },
          (error) => {
            // 获取位置失败，error 包含错误信息
            // console.log(error)
            if (this.$route.query.CompanyId) {
              this.CompanyId = this.$route.query.CompanyId
              this.getCmid(this.CompanyId)
              return
            }
            this.getAddress()
          },
          { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }
      );
    } else {
      // 浏览器不支持Geolocation API
      // console.log('不支持')
      if (this.$route.query.CompanyId) {
        this.CompanyId = this.$route.query.CompanyId
        this.getCmid(this.CompanyId)
        return
      }
      this.getAddress()
    }


  },
  methods: {
    onsearch() {
      if (!this.value) {
        return this.$dialog.alert({message: '请输入正确的值', theme: 'round-button'})
      }
      this.show = true
      this._http2.post('https://interface2.visumall.com/business/shop.ashx?cmd=GetList', {
        "token":{
          "TimeSpan":1517328686398,
          "sToken":"89090FDE8B6B5247015F6BBB1D85DD38"
        },
        "SearchCondition": {
          "IndexMin":0,
          "IndexMax":0,
          "ExpertCompanyId":"",
          "ShopName":this.value
        },
        "ReturnInfo":{}
      })
      .then(res => {
        this.show = false
        if (!res.GetListResult || !res.GetListResult.length) {
          this.$dialog.alert({message: '搜索无该门店', theme: 'round-button'})
        }else {
          this.shopList = [...res.GetListResult]
        }
      })
    },

    onCancel() {
      this.showAddress=false
      this.dzIdx = [...this.dzItemId]
      // console.log(this.gsAddress[this.dzIdx[0]].children[this.dzIdx[1]].AreaId)
      this.getCompanyList(this.gsAddress[this.dzIdx[0]].children[this.dzIdx[1]].AreaId)
    },
    getAddress() {
      this.show = true
      this._http.post('https://interface2.visumall.com/business/Area.ashx?cmd=GetListStruct', {
        "token":{
          "TimeSpan": 532618542085,
          "sToken": "532618542085hvNRUjZTfkyFRrQGb0oN0g6996262EB1AAC747"
        },
        "AreaParentId": "00000000-0000-0000-0000-000000000000"
      })
          .then(res => {
            this.show = false
            let dzcity = res.data.GetListResult.map(item => {
              return JSON.parse(JSON.stringify(item).replace(/ListArea/g, 'children'))
            })
            this.gsAddress = dzcity
            this.getCompanyList(this.gsAddress[0].children[0].AreaId)
            // this.dw(dzcity)
          })
    }, //获取地址
    upaddress(p, v) {
      let idx = this.$refs.getAddress.getIndexes()
      clearTimeout(timer)
      let timer = setTimeout(() => {
        this.dzItemId = idx
        // console.log(this.dzIdx)
      }, 300)
    },
    dw (arr) {
      let map = new AMap.Map("map", {
        resizeEnable: true,
        center: [116.397428, 39.90923],
        zoom: 13
      });
      //实例化城市查询类
      let _this = this
      let  citysearch = new AMap.CitySearch();
      //自动获取用户IP，返回当前城市
      citysearch.getLocalCity(function(status, result) {
        if (status === 'complete' && result.info === 'OK') {
          if (result && result.city && result.bounds) {
            let city = result.city;
            let sf = result.province;
            let sm = []
            for (let i = 0; i < arr.length; i++) {
              if (arr[i].AreaName == sf) {
                sm[0] = i
                for (let j = 0; j < arr[i].children.length; j++) {
                  if (arr[i].children[j].AreaName == city) {
                    sm[1] = j
                    sm[2] = 0
                    break
                  }
                }
              }
            }
            _this.dzIdx = sm
          }
        }
      })
    }, //获取用户定位

    getCompanyList(id) {
      this.show = true
      // https://interface2.visumall.com/business/company.ashx?cmd=GetList
      this._http2.post('https://interface2.visumall.com/business/company.ashx?cmd=GetList', {
        "token":{
          "TimeSpan":1517328686398,
          "sToken":"89090FDE8B6B5247015F6BBB1D85DD38"
        },
        "SearchCondition":{
          "IndexMin":0,
          "IndexMax":0,
          "PageIndex":0,
          "PageSize":0,
          "AreaId": id
        },
        "ReturnInfo":{}
      })
      .then(res => {
        this.show = false
        // console.log(res)
        if (res.GetListResult && res.GetListResult.length) {
          this.CompanyList = [...res.GetListResult]
        }

        this.getShopList(this.CompanyList[0].CompanyId)
      })
    },

    getCmid(id) {
      this.show = true
      //
      this._http2.post('https://interface2.visumall.com/business/company.ashx?cmd=GetList', {
        "token":{
          "TimeSpan":1517328686398,
          "sToken":"89090FDE8B6B5247015F6BBB1D85DD38"
        },
        "SearchCondition":{
          "CompanyId": id,
          IndexMin: 1,
          IndexMax: 1000,
          // "ExpertRole":"optometrist,expert"
          "ExpertbShowInMP":1,//加这句

        },
        "ReturnInfo": {
          Company: true
        }
      })
          .then(res => {
            this.show = false
            // console.log(res)
            if (res.GetListResult && res.GetListResult.length) {
              this.CompanyList = [...res.GetListResult]
            }

            this.getShopList(this.CompanyList[0].CompanyId)
          })
    },

    getShopList(id) {
      this.show = true
      this._http2.post('https://interface2.visumall.com/business/shop.ashx?cmd=GetList', {
        "token":{
          "TimeSpan":1517328686398,
          "sToken":"89090FDE8B6B5247015F6BBB1D85DD38"
        },
        "SearchCondition":{
          "CompanyId": id,
          "IndexMin":1,
          "IndexMax":1000,
          "ExpertbShowInMP":1,
          ShopLongitude: this.jwd.longitude,
          ShopLatitude: this.jwd.latitude,
          // ShopLongitude: 21.912754264201354,
          // ShopLatitude: 110.84975669526447,
        },
        "ReturnInfo":{"Company":true}
      })
      .then(res => {
        this.show = false
        // console.log(res)
        if (res.GetListResult && res.GetListResult.length) {
          this.shopList = [...res.GetListResult]
        }
      })
    },

    upCompany() {
      this.showCompany=false
      this.CompanyIdx = this.$refs.getCompany.getIndexes()[0]
      this.getShopList(this.$refs.getCompany.getValues()[0].CompanyId)
    }
  }
}
</script>

<style scoped lang="scss">
.shopList {
  width: 100vw;
  height: 100vh;
  font-size: 14px;
}
.top {
  width: 100vw;
  background: #BCE2FF;
  padding-bottom: 20px;
  p {
    font-size: 20px;
    padding: 20px 0;
    text-align: center;
  }
  .search {
    width: 90vw;
    margin: 0 auto;
    border-radius: 10px;
  }
  .dw {
    padding-top: 20px;
    display: flex;
    justify-content: center;
    .dwbox {
      width: 40vw;
      text-align: center;
      .dm {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-top: 20px;
      }
      span {
        display: inline;
        padding: 10px 20px;
        border-radius: 7px;
        background: rgba(255, 255, 255, 0.3);
        margin: 0 auto;
      }
    }
  }
}
.content {
  width: 100vw;
  height: calc(100vh - 220px);
  overflow-y: auto;
  background: #FFF1DF;
  .content-box {
    padding: 15px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    margin-bottom: 15px;
    .left {
      img {
        width: 60px;
        max-height: 50px;
      }
      p {
        padding: 5px;
        border-radius: 5px;
        background: #BCE2FF;
        font-weight: 800;
        font-size: 12px;
        margin-top: 24px;
        width: 4em;
      }
    }
    .right {
      padding-left: 20px;
      h3 {
        font-size: 17px;
        font-weight: 800;
        padding-bottom: 10px;
        width: 75vw;
      }
      div {
        display: flex;
        align-items: center;
        img {
          display: block;
          width: 18px;
          height: 18px;
        }
        span {width: 15em;padding-left: 5px;}
      }
      div:nth-child(2) {padding-bottom: 14px;}
    }
  }
}
.fh {
  position: absolute;
  z-index: 99;
  top: 10px;
  left: 10px;
  font-size: 20px;
}
.jwd {
  position: absolute;
  top: -20px;
  right: 0;
  font-size: 12px !important;
  color: #a1a1a1;
}
</style>
